import {
  BuildingElement,
  BuildingElementCalculation,
} from '@/models/building-element/interfaces';
import { CustomError } from '@/models/common/interfaces';
import { ProjectOverview } from '@/models/projects/interfaces';
import { computed, reactive, toRefs } from '@vue/composition-api';
import { useApi } from './api';
import { isSuccess } from '@/helpers/common';
import { useErrorModal } from './error';
import { useSnackbar } from './snackbar';
import {
  buildElementCalculations,
  createAdditionElements,
  mapBuildingElementCalcProperties,
  updateBuildingItemSortOrder,
} from '@/helpers/building-element';
import { useCalculation } from './calculation';
import { Ns3451 } from '@/models/standards/ns3451/interfaces';
import { v4 as uuidv4 } from 'uuid';
import { addAdditionsToElement } from '@/helpers/building-element';
import { BatchType } from '@/constants/enum';
import { useBatch } from './batch';
import { BuildingElementTemplate } from '@/models/library/interfaces';
import { sortBuildingItemTemplates } from '@/helpers/library';
import { useBuildingItem } from './building-item';
import { buildItemCalculations } from '@/helpers/building-item';
import { buildAdditionItemCalculations } from '@/helpers/addition-item';

interface State {
  error?: CustomError;
}

const state = reactive<State>({
  error: undefined,
});

export const useBuildingElement = () => {
  const { errorModal } = useErrorModal();
  const { snack } = useSnackbar();

  const addBuildingElementToCalculation = (element: BuildingElement) => {
    const { projectCalculation } = useCalculation();
    const newCalcElement = mapBuildingElementCalcProperties(element);

    if (!projectCalculation.value) return;

    newCalcElement.Project = projectCalculation.value;
    newCalcElement.ProjectId = projectCalculation.value.Id;
    newCalcElement.IsRecentlyAdded = true;

    if (element.Items) {
      newCalcElement.BuildingItems = buildItemCalculations(
        element.Items,
        newCalcElement
      );
    }

    if (element.AdditionItems && newCalcElement.Unit?.value === 'm2') {
      newCalcElement.AdditionItems = buildAdditionItemCalculations(
        element.AdditionItems,
        newCalcElement
      );
    }

    if (projectCalculation.value?.BuildingElements) {
      projectCalculation.value.BuildingElements.push(newCalcElement);
    }
  };

  const deleteElementFromCalculation = async (
    element: BuildingElementCalculation
  ): Promise<void> => {
    const { projectCalculation } = useCalculation();
    const { registerElementBatchOperation } = useBatch();
    if (projectCalculation.value?.BuildingElements) {
      projectCalculation.value.BuildingElements.forEach(ele => {
        if (
          ele.Id === element.Id &&
          projectCalculation.value?.BuildingElements
        ) {
          const index = projectCalculation.value.BuildingElements.indexOf(ele);
          projectCalculation.value.BuildingElements.splice(index, 1);
          registerElementBatchOperation(element, BatchType.Delete);
          snack('snack.element-deleted', true);
        }
      });
    }
  };

  const getElements = async (
    projectId: string
  ): Promise<Array<BuildingElement> | undefined> => {
    const { get } = useApi(`/project/${projectId}/buildingelement`);

    let elements: Array<BuildingElement> | undefined = undefined;

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        elements = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return elements;
  };

  const initElements = async (
    elements: Array<BuildingElement>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    project: any
  ): Promise<Array<BuildingElementCalculation>> => {
    return buildElementCalculations(elements, project);
  };

  const getElementsFromCalculations = async (
    calculations: Array<ProjectOverview>
  ): Promise<Array<BuildingElementCalculation> | undefined> => {
    const { projectCalculation } = useCalculation();
    let elements: Array<BuildingElementCalculation> = [];
    if (projectCalculation?.value) {
      for (const calc of calculations) {
        if (calc.Id) {
          const _elements = await getElements(calc.Id);
          if (_elements && projectCalculation?.value) {
            const _elementsCalc = await initElements(
              _elements,
              projectCalculation.value
            );
            if (_elementsCalc) {
              elements = elements.concat(_elementsCalc);
            }
          }
        }
      }
    }

    return elements;
  };

  const addFirstelement = computed(() => {
    const { projectCalculation } = useCalculation();
    return (
      projectCalculation?.value &&
      projectCalculation.value.BuildingElements &&
      projectCalculation.value.BuildingElements.length === 0
    );
  });

  const createElementFromNS3451 = async (
    ns3451: Ns3451
  ): Promise<BuildingElement | undefined> => {
    const { projectCalculation } = useCalculation();

    if (!projectCalculation?.value) return;

    const newElement: BuildingElement = {
      Id: uuidv4(),
      NS3450: projectCalculation?.value?.Profession?.Code ?? null,
      Transport: 0,
      Name: ns3451.Name,
      NS3451: ns3451.Number.toString(),
      Amount: null,
      Unit: 'm2',
      Items: [],
      AdditionItems: [],
      ProductionLine: null,
      SortOrder: projectCalculation.value.BuildingElements.length,
      Comments: null,
      ProjectId: null,
    };

    const additions = createAdditionElements(newElement);
    newElement.AdditionItems = additions;

    return newElement;
  };

  const createElementCalcFromNS3451 = async (
    ns3451: Ns3451
  ): Promise<BuildingElementCalculation | undefined> => {
    const { projectCalculation } = useCalculation();
    const {
      registerElementBatchOperation,
      registerAdditionItemBatchOperation,
    } = useBatch();

    if (!projectCalculation?.value) return;

    const newElement: BuildingElement = {
      Id: uuidv4(),
      NS3450: projectCalculation?.value?.Profession?.Code ?? null,
      Transport: 0,
      Name: ns3451.Name,
      NS3451: ns3451.Number.toString(),
      Amount: null,
      Unit: 'm2',
      Items: [],
      AdditionItems: [],
      ProductionLine: null,
      SortOrder: projectCalculation.value.BuildingElements.length,
      Comments: null,
      ProjectId: null,
    };

    const newCalcElement = mapBuildingElementCalcProperties(newElement);
    newCalcElement.Project = projectCalculation.value;
    newCalcElement.ProjectId = projectCalculation.value.Id;
    newCalcElement.NS3450 = projectCalculation.value.Profession?.Code ?? null;
    newCalcElement.IsRecentlyAdded = true;

    addAdditionsToElement(newCalcElement);

    newCalcElement.AdditionItems.forEach(aic =>
      registerAdditionItemBatchOperation(aic, BatchType.Insert)
    );
    await registerElementBatchOperation(newCalcElement, BatchType.Insert);

    return newCalcElement;
  };

  const createFromBuildingElementTemplate = async (
    template: BuildingElementTemplate
  ): Promise<BuildingElementCalculation | undefined> => {
    const { projectCalculation } = useCalculation();
    const { registerElementInsertChangesWithChildren } = useBatch();
    const { createBICFromBuildingItemTemplate } = useBuildingItem();

    if (!projectCalculation.value) return;

    if (!projectCalculation.value.BuildingElements) {
      projectCalculation.value.BuildingElements = [];
    }

    const ele: BuildingElement = {
      Name: template.Name,
      NS3451: template.NS3451,
      Unit: template.Unit,
      Comments: template.Comments,
      Transport: null,
      AdditionItems: [],
      Items: [],
      ProjectId: null,
      ProductionLine: template.ProductionLine,
      SortOrder: projectCalculation.value.BuildingElements.length,
      NS3450: template.NS3450,
      Id: uuidv4(),
      Amount: null,
    };

    const eleCalc = mapBuildingElementCalcProperties(ele);
    eleCalc.Project = projectCalculation.value;
    eleCalc.ProjectId = projectCalculation.value.Id;
    eleCalc.NS3450 = projectCalculation.value.Profession?.Code ?? null;

    const sortedList = sortBuildingItemTemplates(
      template.BuildingItemTemplates
    );

    sortedList.forEach(temp => {
      if (eleCalc.Id) {
        const item = createBICFromBuildingItemTemplate(
          temp,
          eleCalc,
          eleCalc.Id
        );
        eleCalc.BuildingItems.push(item);
      }
    });

    updateBuildingItemSortOrder(eleCalc.BuildingItems);

    projectCalculation.value.BuildingElements.push(eleCalc);

    if (eleCalc.Unit?.value === 'm2') {
      addAdditionsToElement(eleCalc);
    }

    registerElementInsertChangesWithChildren(eleCalc);

    snack('snack.added-to-calculation', true);

    return eleCalc;
  };

  return {
    ...toRefs(state),
    getElementsFromCalculations,
    createElementCalcFromNS3451,
    createElementFromNS3451,
    initElements,
    getElements,
    deleteElementFromCalculation,
    createFromBuildingElementTemplate,
    addBuildingElementToCalculation,
    addFirstelement,
  };
};
