import {
  AdditionItem,
  AdditionItemCalculation,
  AdditionType,
  BaseBuildingElement,
  BuildingElement,
  BuildingElementCalculation,
  BuildingItem,
  BuildingItemCalculation,
} from '@/models/building-element/interfaces';
import { ProjectCalculation } from '@/models/calculation/interfaces';
import { useCalculation } from '@/modules/calculation';
import { computed } from '@vue/composition-api';
import { buildAdditionItemCalculations } from './addition-item';
import { buildItemCalculations, calculateItemWarnings } from './building-item';
import { roundToTwoDecimals } from './common';
import { v4 as uuidv4 } from 'uuid';
import { useBatch } from '@/modules/batch';
import { defaultLocaleString } from '@/localization';

export const createAdditionElements = (
  element: BuildingElement | BuildingElementCalculation
): Array<AdditionItem> => {
  const additions: Array<AdditionItem> = [];
  const currentDate = new Date();

  const surface: AdditionItem = {
    Id: uuidv4(),
    Name: AdditionType[AdditionType.Flatetillegg],
    SortOrder: 1,
    Amount: 0,
    Unit: 'stk',
    Created: currentDate.toString(),
    LastModified: currentDate.toString(),
    BuildingElementId: element.Id,
  };
  const opening: AdditionItem = {
    Id: uuidv4(),
    Name: AdditionType[AdditionType.Åpninger],
    SortOrder: 0,
    Amount: 0,
    Unit: 'stk',
    Created: currentDate.toString(),
    LastModified: currentDate.toString(),
    BuildingElementId: element.Id,
  };

  additions.push(surface);
  additions.push(opening);

  return additions;
};

export const addAdditionsToElement = (element: BuildingElementCalculation) => {
  const additions = createAdditionElements(element);
  element.AdditionItems = buildAdditionItemCalculations(additions, element);
};

export const removeAdditionsFromElement = (
  element: BuildingElementCalculation
) => {
  element.AdditionItems = [];
};

export const calculateWarnings = (calculation: ProjectCalculation) => {
  if (calculation.BuildingElements && calculation.BuildingElements.length > 0) {
    calculation.BuildingElements.forEach(element => {
      element.ElementWarnings = [];
      if (element.Amount == null || element.Amount === 0) {
        element.ElementWarnings.push('info.no-amount');
      }

      calculateItemWarnings(element);
    });
  }
};

export const mapBuildingElementCalcProperties = (
  element: BuildingElement
): BuildingElementCalculation => {
  const { getCalculationFactorValue, projectCalculation } = useCalculation();
  const { registerElementUnitChanges } = useBatch();

  const _elementCalc: BuildingElementCalculation = {
    TransportAddition: null,
    FollowupDate: null,
    Project: undefined,
    Signed: null,
    TransportationAddition: null,
    Id: element.Id,
    Name: element.Name,
    ProjectId: element.ProjectId,
    ProductionLine: element.ProductionLine,
    SortOrder: element.SortOrder,
    NS3450: element.NS3450,
    NS3451: element.NS3451,
    Amount: element.Amount,
    ElementWarnings: [],
    Unit: computed({
      get() {
        return element.Unit ?? '';
      },
      set(val) {
        element.Unit = val;
        if (val && val.toLocaleLowerCase() === 'm2') {
          if (
            _elementCalc.AdditionItems &&
            _elementCalc.AdditionItems.length === 0
          ) {
            addAdditionsToElement(_elementCalc);
            registerElementUnitChanges(_elementCalc, true);
          }
        } else {
          registerElementUnitChanges(_elementCalc, false);
          removeAdditionsFromElement(_elementCalc);
        }
      },
    }),
    Factor1: element.Factor1,
    Factor5: element.Factor5,
    Transport: element.Transport,
    Comments: element.Comments,
    Created: element.Created,
    LastModified: element.LastModified,
    HoursAdjusted: element.HoursAdjusted,
    SalaryCostAdjusted: element.SalaryCostAdjusted,
    MaterialCostAdjusted: element.MaterialCostAdjusted,
    IsFDVExportSelected: computed({
      get() {
        return (
          _elementCalc.BuildingItems.length > 0 &&
          !_elementCalc.BuildingItems.some(
            item => item.IsFDVExportSelected === false
          )
        );
      },
      set(val) {
        if (_elementCalc.IsFDVExportSelected.value !== val) {
          _elementCalc.BuildingItems.forEach(item => {
            if (item.NOBBNumber != null) {
              item.IsFDVExportSelected = val;
            }
          });
        }
      },
    }),
    SurfaceAddition: computed(() => {
      let surface = 0;
      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.HasBuildingItems.value &&
        _elementCalc.BuildingItems
      ) {
        const items = _elementCalc.BuildingItems.filter(
          item =>
            item.AmountCalculated.value !== null &&
            item.AmountCalculated.value > 0
        );

        items.forEach(item => {
          surface += item.SurfaceAddition ?? 0;
        });
      }
      return surface;
    }),
    Factor5Calculated: computed({
      get() {
        let factor5: Nullable<number> = null;

        if (_elementCalc.Project) {
          factor5 = getCalculationFactorValue(5, _elementCalc.Project);
        }

        if (_elementCalc.Factor5 != null) return _elementCalc.Factor5;

        return factor5 ?? null;
      },
      set(val) {
        _elementCalc.Factor5 = val;
      },
    }),
    OpeningAddition: computed(() => {
      let opening = 0;
      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.HasBuildingItems.value &&
        _elementCalc.BuildingItems
      ) {
        const items = _elementCalc.BuildingItems.filter(
          item =>
            item.AmountCalculated.value !== null &&
            item.AmountCalculated.value > 0
        );

        items.forEach(item => {
          opening += item.OpeningAddition ?? 0;
        });
      }
      return opening;
    }),
    Factor1Calculated: computed({
      get() {
        let factor = 0;
        let calcFactor1: Nullable<number> = null;

        if (_elementCalc.Project) {
          calcFactor1 = getCalculationFactorValue(1, _elementCalc.Project);
        }

        if (_elementCalc.Factor1 != null) {
          factor = _elementCalc.Factor1;
        } else if (calcFactor1 !== null) {
          factor = calcFactor1;
        }

        return factor;
      },
      set(val) {
        _elementCalc.Factor1 = val;
      },
    }),
    SalaryCostAdjustedCalculated: computed(() => {
      let salaryCostAdjusted = 0;
      let f2: Nullable<number> = null;
      let f3: Nullable<number> = null;

      if (_elementCalc.Project) {
        f2 = getCalculationFactorValue(2, _elementCalc.Project);
        f3 = getCalculationFactorValue(3, _elementCalc.Project);
      }
      if (projectCalculation.value && projectCalculation.value.Factors) {
        if (_elementCalc.HoursAdjusted === null) {
          salaryCostAdjusted = _elementCalc.SalaryCostAdjusted ?? 0;
        } else if (_elementCalc.HoursAdjusted) {
          // Justering av produksjonslønn
          const factor2 = f2 ?? 0;
          // Sosiale kostnader
          const factor3 = f3 ?? 0;
          const adjusted = _elementCalc.HoursAdjusted * factor2 * factor3;

          salaryCostAdjusted = adjusted ?? 0;
        }
      }

      return salaryCostAdjusted;
    }),
    SumTime: computed(() => {
      let time = 0;

      if (_elementCalc.BuildingItems) {
        _elementCalc.BuildingItems.forEach(item => {
          time += item.SumTime.value ?? 0;
        });
      }

      return time;
    }),
    AmountOfMaterials: computed(() => {
      let amountMat = 0;

      if (_elementCalc.BuildingItems) {
        _elementCalc.BuildingItems.forEach(item => {
          amountMat += item.AmountOfMaterials.value ?? 0;
        });
      }

      return amountMat;
    }),
    ItemCount: computed(() => {
      let count = 0;
      if (_elementCalc.BuildingItems) {
        count = _elementCalc.BuildingItems.length;
      }

      return count;
    }),
    AmountCalculated: computed(() => {
      let amount = 0;

      if (_elementCalc.BuildingItems) {
        _elementCalc.BuildingItems.forEach(item => {
          if (item.AmountCalculated.value !== null) {
            amount += item.AmountCalculated.value;
          }
        });
      }

      return amount;
    }),
    HasBuildingItems: computed(() => {
      return (
        _elementCalc.BuildingItems !== undefined &&
        _elementCalc.BuildingItems.length > 0
      );
    }),
    IsAmountSet: computed(() => {
      return (
        _elementCalc.Amount != null &&
        roundToTwoDecimals(_elementCalc.Amount) !== 0
      );
    }),
    SumTotal: computed(() => {
      let total = 0;
      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.HasBuildingItems.value &&
        _elementCalc.BuildingItems
      ) {
        _elementCalc.BuildingItems.forEach(item => {
          total += item.SumTotal.value ?? 0;
        });
        if (_elementCalc.AdditionItems) {
          _elementCalc.AdditionItems.forEach(item => {
            total += item.SumTotal.value ?? 0;
          });
        }
      }
      return total;
    }),
    UnitPrice: computed(() => {
      let unitPrice = 0;

      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.SumTotal.value &&
        _elementCalc.Amount != null
      ) {
        unitPrice = Math.round(
          _elementCalc.SumTotal.value / _elementCalc.Amount
        );
      }

      return unitPrice;
    }),
    SumHours: computed(() => {
      let sum = 0;

      if (_elementCalc.BuildingItems && _elementCalc.IsAmountSet.value) {
        _elementCalc.BuildingItems.forEach(item => {
          sum += item.SumHours.value ?? 0;
        });
        if (_elementCalc.AdditionItems) {
          _elementCalc.AdditionItems.forEach(item => {
            sum += item.SumHours.value ?? 0;
          });
        }
      }

      return sum;
    }),
    TimePrUnit: computed(() => {
      let time = 0;

      if (_elementCalc.IsAmountSet.value && _elementCalc.SumHours.value) {
        const sumHours = _elementCalc.SumHours.value;
        if (
          sumHours > 0 &&
          _elementCalc.Amount != null &&
          _elementCalc.Amount > 0
        ) {
          time = sumHours / _elementCalc.Amount;
        }
      }

      return time;
    }),
    SumSalaryAndSocialCost: computed(() => {
      let cost = 0;

      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.HasBuildingItems.value &&
        _elementCalc.BuildingItems
      ) {
        _elementCalc.BuildingItems.forEach(item => {
          cost += item.SumSalaryAndSocialCost.value ?? 0;
        });
        if (_elementCalc.AdditionItems) {
          _elementCalc.AdditionItems.forEach(item => {
            cost += item.SumSalaryAndSocialCost.value ?? 0;
          });
        }
      }

      return cost;
    }),
    SumSalaryAndExpensesAdjusted: computed(() => {
      let adjusted = 0;

      if (_elementCalc.IsAmountSet.value) {
        adjusted =
          _elementCalc.SumSalaryAndSocialCost.value +
          _elementCalc.SalaryCostAdjustedCalculated.value;
      }

      return adjusted;
    }),
    SumHoursAdjusted: computed(() => {
      let adjusted = 0;
      if (_elementCalc.SumHours.value > 0) {
        adjusted =
          _elementCalc.SumHours.value + (_elementCalc.HoursAdjusted ?? 0);
      }
      return adjusted;
    }),
    SumSelfCostMaterialsSubContractors: computed(() => {
      let selfCostMatSub = 0;
      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.HasBuildingItems?.value &&
        _elementCalc.BuildingItems
      ) {
        _elementCalc.BuildingItems.forEach(item => {
          selfCostMatSub += item.SumSelfCostMaterialsSubContractors.value ?? 0;
        });
      }
      return selfCostMatSub;
    }),
    SumMaterialsAndCostAdjusted: computed(() => {
      return (
        _elementCalc.SumSelfCostMaterialsSubContractors.value +
        (_elementCalc.MaterialCostAdjusted ?? 0)
      );
    }),
    SumProductionSalary: computed(() => {
      let salary = 0;

      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.HasBuildingItems.value &&
        _elementCalc.BuildingItems
      ) {
        _elementCalc.BuildingItems.forEach(item => {
          salary += item.SumProductionSalary.value ?? 0;
        });
        if (_elementCalc.AdditionItems) {
          _elementCalc.AdditionItems.forEach(item => {
            salary += item.SumProductionSalary.value ?? 0;
          });
        }
      }

      return salary;
    }),
    Factor2: computed(() => {
      let factor = 1;

      if (_elementCalc.IsAmountSet.value && element.Amount != null) {
        if (_elementCalc.SumProductionSalary.value > 0 && element.Amount > 0) {
          factor = _elementCalc.SumProductionSalary.value / element.Amount;
        }
      }

      return factor;
    }),
    Factor3: computed(() => {
      let factor = 0;
      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.HasBuildingItems.value &&
        _elementCalc.BuildingItems
      ) {
        _elementCalc.BuildingItems.forEach(item => {
          factor += item.Factor3.value ?? 0;
        });
        if (_elementCalc.AdditionItems) {
          _elementCalc.AdditionItems.forEach(item => {
            factor += item.Factor3.value ?? 0;
          });
        }
      }
      return factor;
    }),
    Factor4: computed(() => {
      let factor = 0;
      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.HasBuildingItems.value &&
        _elementCalc.BuildingItems
      ) {
        _elementCalc.BuildingItems.forEach(item => {
          factor += item.Factor4.value ?? 0;
        });

        if (_elementCalc.AdditionItems) {
          _elementCalc.AdditionItems.forEach(item => {
            factor += item.Factor4.value ?? 0;
          });
        }
      }
      return factor;
    }),
    Factor6: computed(() => {
      let factor = 0;
      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.HasBuildingItems.value &&
        _elementCalc.BuildingItems
      ) {
        _elementCalc.BuildingItems.forEach(item => {
          factor += item.Factor6.value ?? 0;
        });
        if (_elementCalc.AdditionItems) {
          _elementCalc.AdditionItems.forEach(item => {
            factor += item.Factor6.value ?? 0;
          });
        }
      }
      return factor;
    }),
    Factor7: computed(() => {
      let factor = 0;
      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.HasBuildingItems.value &&
        _elementCalc.BuildingItems
      ) {
        _elementCalc.BuildingItems.forEach(item => {
          factor += item.Factor7.value ?? 0;
        });
      }
      return factor;
    }),
    ProjectCost: computed(() => {
      let cost = 0;

      if (
        _elementCalc.Factor3.value &&
        _elementCalc.Factor3.value > 0 &&
        _elementCalc.SumSelfCostMaterialsSubContractors.value &&
        _elementCalc.SumProductionSalary.value
      ) {
        const sumSelfCostMaterialsSubContractors =
          _elementCalc.SumSelfCostMaterialsSubContractors.value;
        const sumProductionSalary = _elementCalc.SumProductionSalary.value;
        if (sumSelfCostMaterialsSubContractors > 0 && sumProductionSalary > 0) {
          cost =
            sumSelfCostMaterialsSubContractors +
            sumProductionSalary +
            _elementCalc.Factor3.value;
        }
      }

      return cost;
    }),
    SumSelfCostSalaryAndExpenses: computed(() => {
      let salaryExpense = 0;

      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.HasBuildingItems.value &&
        _elementCalc.BuildingItems
      ) {
        _elementCalc.BuildingItems.forEach(item => {
          salaryExpense += item.SumSelfCostSalaryAndExpenses.value ?? 0;
        });
        if (_elementCalc.AdditionItems) {
          _elementCalc.AdditionItems.forEach(item => {
            salaryExpense += item.SumSelfCostSalaryAndExpenses.value ?? 0;
          });
        }
      }

      return salaryExpense;
    }),
    SumSalaryAndExpenses: computed(() => {
      let salaryExpense = 0;

      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.HasBuildingItems.value &&
        _elementCalc.BuildingItems
      ) {
        _elementCalc.BuildingItems.forEach(item => {
          salaryExpense += item.SumSalaryAndExpenses.value ?? 0;
        });
        if (_elementCalc.AdditionItems) {
          _elementCalc.AdditionItems.forEach(item => {
            salaryExpense += item.SumSalaryAndExpenses.value ?? 0;
          });
        }
      }

      return salaryExpense;
    }),
    UnitPriceMaterials: computed(() => {
      let price = 0;
      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.HasBuildingItems.value &&
        _elementCalc.Amount
      ) {
        if (
          _elementCalc.SumSelfCostMaterialsSubContractors.value &&
          _elementCalc.SumSelfCostMaterialsSubContractors.value > 0
        ) {
          price =
            _elementCalc.SumSelfCostMaterialsSubContractors.value /
            _elementCalc.Amount;
        }
      }

      return price;
    }),
    SumMaterialsUE: computed(() => {
      let materials = 0;
      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.HasBuildingItems.value &&
        _elementCalc.BuildingItems
      ) {
        _elementCalc.BuildingItems.forEach(item => {
          materials += item.SumMaterialsUE.value ?? 0;
        });
      }

      return materials;
    }),
    SumSelfCost: computed(() => {
      let selfCost = 0;
      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.HasBuildingItems.value &&
        _elementCalc.BuildingItems
      ) {
        _elementCalc.BuildingItems.forEach(item => {
          selfCost += item.SumSelfCost.value ?? 0;
        });
        if (_elementCalc.AdditionItems) {
          _elementCalc.AdditionItems.forEach(item => {
            selfCost += item.SumSelfCost.value ?? 0;
          });
        }
      }
      return selfCost;
    }),
    SumProfit: computed(() => {
      let profit = 0;
      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.HasBuildingItems.value &&
        _elementCalc.BuildingItems
      ) {
        _elementCalc.BuildingItems.forEach(item => {
          profit += item.SumProfit.value ?? 0;
        });
        if (_elementCalc.AdditionItems) {
          _elementCalc.AdditionItems.forEach(item => {
            profit += item.SumProfit.value ?? 0;
          });
        }
      }
      return profit;
    }),
    SumAmountControl: computed(() => {
      let amount = 0;

      if (
        _elementCalc.IsAmountSet.value &&
        _elementCalc.HasBuildingItems.value &&
        _elementCalc.BuildingItems
      ) {
        _elementCalc.BuildingItems.forEach(item => {
          amount += item.DeviationPrice.value ?? 0;
        });
      }
      return amount;
    }),
    Price: computed(() => {
      let price = 0;

      if (_elementCalc.HasBuildingItems.value && _elementCalc.BuildingItems) {
        const calculated = _elementCalc.BuildingItems.filter(
          item =>
            item.AmountCalculated.value != null &&
            item.AmountCalculated.value > 0
        );

        calculated.forEach(item => {
          if (item.Price != null && item.Price > 0) {
            price += Number(item.Price);
          }
        });
      }
      return price;
    }),
    Items: [],
    BuildingItems: [],
    AdditionItems: [],
    IsHighLighted: computed(() => {
      return false;
    }),
    IsRecentlyAdded: false,
    IsSelected: false,
    IsExpanded: false,
    HoursAdjustedVM: computed({
      get() {
        return _elementCalc.HoursAdjusted ?? 0;
      },
      set(val) {
        _elementCalc.HoursAdjusted = val;
        _elementCalc.SalaryCostAdjusted = null;
      },
    }),
    NS3451TopLevel: computed(() => {
      let first = 0;

      if (_elementCalc.NS3451) {
        const firstChar = _elementCalc.NS3451.charAt(0);
        first = +firstChar;
      }

      return first;
    }),
    NS3451SecondLevel: computed(() => {
      let second = 0;

      if (_elementCalc.NS3451 && _elementCalc.NS3451.length > 1) {
        const secondChar = _elementCalc.NS3451.charAt(1);
        second = +secondChar;
      }

      return second;
    }),
    NS3451ThirdLevel: computed(() => {
      let third = 0;

      if (_elementCalc.NS3451 && _elementCalc.NS3451.length > 2) {
        const thirdChar = _elementCalc.NS3451.charAt(2);
        third = +thirdChar;
      }

      return third;
    }),
    SumMargin: computed(() => {
      let margin = 0;
      if (
        _elementCalc.Factor4.value != null &&
        _elementCalc.SumProfit.value != null
      ) {
        margin = _elementCalc.Factor4.value + _elementCalc.SumProfit.value;
      }

      return margin;
    }),
    DeliveryOrder: computed({
      get() {
        return null;
      },
      set(val) {
        _elementCalc.BuildingItems.forEach(item => {
          if (!item.DeliveryOrder) {
            item.DeliveryOrder = val;
          }
        });
      },
    }),
  };

  return _elementCalc;
};

export const elementSumTotal = (element: BuildingElementCalculation) => {
  let total = 0;
  if (
    element.IsAmountSet.value &&
    element.HasBuildingItems.value &&
    element.BuildingItems
  ) {
    element.BuildingItems.forEach(item => {
      total += item.SumTotal.value ?? 0;
    });
    if (element.AdditionItems) {
      element.AdditionItems.forEach(item => {
        total += item.SumTotal.value ?? 0;
      });
    }
  }
  return total;
};

export const elementSumProfit = (element: BuildingElementCalculation) => {
  let profit = 0;
  if (
    element.IsAmountSet.value &&
    element.HasBuildingItems.value &&
    element.BuildingItems
  ) {
    element.BuildingItems.forEach(item => {
      profit += item.SumProfit.value ?? 0;
    });
    if (element.AdditionItems) {
      element.AdditionItems.forEach(item => {
        profit += item.SumProfit.value ?? 0;
      });
    }
  }
  return profit;
};

export const elementSumMargin = (element: BuildingElementCalculation) => {
  let margin = 0;
  element.BuildingItems.forEach(item => {
    margin += item.SumMargin.value ?? 0;
  });
  return margin;
};

export const elementSumSelfCostMaterialsSubContractors = (
  element: BuildingElementCalculation
) => {
  let selfCostMatSub = 0;
  if (
    element.IsAmountSet.value &&
    element.HasBuildingItems?.value &&
    element.BuildingItems
  ) {
    element.BuildingItems.forEach(item => {
      selfCostMatSub += item.SumSelfCostMaterialsSubContractors.value ?? 0;
    });
  }
  return selfCostMatSub;
};

export const elementSumHours = (element: BuildingElementCalculation) => {
  let sum = 0;

  if (element.BuildingItems && element.IsAmountSet.value) {
    element.BuildingItems.forEach(item => {
      sum += item.SumHours.value ?? 0;
    });
    if (element.AdditionItems) {
      element.AdditionItems.forEach(item => {
        sum += item.SumHours.value ?? 0;
      });
    }
  }

  return sum;
};

export const sortBuildingItemsCalculation = (
  items: Array<BuildingItemCalculation>
): Array<BuildingItemCalculation> => {
  const sortedItems = items.sort((a, b) =>
    (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
      ? 1
      : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
      ? -1
      : 0
  );
  return sortedItems;
};

export const sortAdditionItemsCalculation = (
  items: Array<AdditionItemCalculation>
): Array<AdditionItemCalculation> => {
  const sortedItems = items.sort((a, b) =>
    (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
      ? 1
      : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
      ? -1
      : 0
  );
  return sortedItems;
};

export const sortBuildingItems = (
  items: Array<BuildingItem>
): Array<BuildingItem> => {
  const sortedItems = items.sort((a, b) =>
    (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
      ? 1
      : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
      ? -1
      : 0
  );
  return sortedItems;
};

export const updateBuildingItemSortOrderIfMissingSortOrder = (
  items: Array<BuildingItem>
): Array<BuildingItem> => {
  let missingSortOrder = true;
  items.forEach(item => {
    if (item.SortOrder !== 0) {
      missingSortOrder = false;
    }
  });

  if (!missingSortOrder) {
    return items;
  }

  items.forEach((item, index) => {
    item.SortOrder = index;
  });

  return items;
};

export const sortAdditionItems = (
  items: Array<AdditionItem>
): Array<AdditionItem> => {
  const sortedItems = items.sort((a, b) =>
    (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
      ? 1
      : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
      ? -1
      : 0
  );
  return sortedItems;
};

export const updateBuildingElementSortOrder = (
  elements: Array<BuildingElementCalculation>
) => {
  elements.forEach((ele, index) => {
    ele.SortOrder = index;
  });
};

export const updateBuildingItemSortOrder = (
  items: Array<BuildingItemCalculation>
): Array<BuildingItemCalculation> => {
  items.forEach((item, index) => {
    item.SortOrder = index;
  });
  return items;
};

export const upsertBuildingItems = (
  items: Array<BuildingItemCalculation>
): Array<BuildingItemCalculation> => {
  const sortedItems = items.sort((a, b) =>
    (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
      ? 1
      : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
      ? -1
      : 0
  );
  updateBuildingItemSortOrder(sortedItems);
  return sortedItems;
};

export const onlySortBuildingElementsCalculation = (
  elements: Array<BuildingElementCalculation>
): Array<BuildingElementCalculation> => {
  const sortedElements = elements.sort((a, b) =>
    (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
      ? 1
      : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
      ? -1
      : 0
  );
  return sortedElements;
};

export const sortBuildingElementsCalculation = (
  elements: Array<BuildingElementCalculation>
): Array<BuildingElementCalculation> => {
  const sortedElements = onlySortBuildingElementsCalculation(elements);

  updateBuildingElementSortOrder(sortedElements);

  sortedElements.forEach(ele => {
    if (ele.BuildingItems) {
      ele.BuildingItems = sortBuildingItemsCalculation(ele.BuildingItems);
      updateBuildingItemSortOrder(ele.BuildingItems);
    }
  });

  sortedElements.forEach(ele => {
    if (ele.AdditionItems) {
      ele.AdditionItems = sortAdditionItemsCalculation(ele.AdditionItems);
    }
  });
  return sortedElements;
};

export const sortBuildingElements = (
  elements: Array<BuildingElement>
): Array<BuildingElement> => {
  const sortedElements = elements.sort((a, b) =>
    (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
      ? 1
      : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
      ? -1
      : 0
  );

  sortedElements.forEach(ele => {
    if (ele.Items) {
      ele.Items = sortBuildingItems(ele.Items);
    }
  });

  sortedElements.forEach(ele => {
    if (ele.AdditionItems) {
      ele.AdditionItems = sortAdditionItems(ele.AdditionItems);
    }
  });
  return sortedElements;
};

export const sortBuildingElementsByNS3451 = (
  elements: Array<BuildingElementCalculation>
): Array<BuildingElementCalculation> => {
  const sortedElements = elements.sort((a, b) =>
    ('' + a.NS3451 ?? '').localeCompare(b.NS3451 ?? '' + '')
  );
  return sortedElements;
};

export const buildElementCalculations = (
  elements: Array<BuildingElement>,
  project: ProjectCalculation
): Array<BuildingElementCalculation> => {
  const _elementsCalc: Array<BuildingElementCalculation> = [];

  elements.forEach(ele => {
    // Create calc variant
    const eleCalc = mapBuildingElementCalcProperties(ele);

    eleCalc.Project = project;
    eleCalc.ProjectId = project.Id;
    eleCalc.NS3450 = project.Profession?.Code ?? null;

    if (ele.Items) {
      eleCalc.BuildingItems = buildItemCalculations(ele.Items, eleCalc);
    }

    if (ele.AdditionItems) {
      eleCalc.AdditionItems = buildAdditionItemCalculations(
        ele.AdditionItems,
        eleCalc
      );
    }
    _elementsCalc.push(eleCalc);
  });

  sortBuildingElementsCalculation(_elementsCalc);
  return _elementsCalc;
};

export const mapElementCalcToBaseElement = (
  element: BuildingElementCalculation
): BaseBuildingElement => {
  return {
    Created: element.Created,
    Id: element.Id,
    LastModified: element.LastModified,
    Name: element.Name ?? null,
    ProjectId: element.ProjectId ?? null,
    ProductionLine: element.ProductionLine ?? null,
    SortOrder: element.SortOrder ?? 0,
    NS3450: element.NS3450 ?? null,
    NS3451: element.NS3451 ?? null,
    Amount: element.Amount,
    Unit: element.Unit?.value,
    Factor1: element.Factor1,
    Factor5: element.Factor5,
    Transport: element.Transport,
    Comments: element.Comments ?? null,
    HoursAdjusted: element.HoursAdjusted,
    SalaryCostAdjusted: element.SalaryCostAdjusted,
    MaterialCostAdjusted: element.MaterialCostAdjusted,
  };
};

export const getElementTotalByProp = (
  propName: string,
  element: BuildingElementCalculation
) => {
  switch (propName) {
    case 'SumTotal':
      return element.SumTotal.value.toLocaleString(defaultLocaleString, {
        maximumFractionDigits: 0,
      });
    case 'SumHours':
      return element.SumHours.value.toLocaleString(defaultLocaleString, {
        maximumFractionDigits: 2,
      });
    case 'SumTime':
      return (
        element.SumTime.value &&
        element.SumTime.value.toLocaleString(defaultLocaleString, {
          maximumFractionDigits: 2,
        })
      );
    case 'Factor1':
      return (
        element.Factor1 &&
        element.Factor1.toLocaleString(defaultLocaleString, {
          maximumFractionDigits: 2,
        })
      );
    case 'Factor2':
      return (
        element.Factor2.value &&
        element.Factor2.value.toLocaleString(defaultLocaleString, {
          maximumFractionDigits: 2,
        })
      );
    case 'Factor3':
      return (
        element.Factor3.value &&
        element.Factor3.value.toLocaleString(defaultLocaleString, {
          maximumFractionDigits: 2,
        })
      );
    case 'Factor4':
      return (
        element.Factor4.value &&
        element.Factor4.value.toLocaleString(defaultLocaleString, {
          maximumFractionDigits: 2,
        })
      );
    case 'Factor6':
      return (
        element.Factor6.value &&
        element.Factor6.value.toLocaleString(defaultLocaleString, {
          maximumFractionDigits: 2,
        })
      );
    case 'Factor7':
      return (
        element.Factor7.value &&
        element.Factor7.value.toLocaleString(defaultLocaleString, {
          maximumFractionDigits: 2,
        })
      );
    case 'SumMaterialsUE':
      return element.SumMaterialsUE.value.toLocaleString(defaultLocaleString, {
        maximumFractionDigits: 0,
      });
    case 'SumSelfCostMaterialsSubContractors':
      return element.SumSelfCostMaterialsSubContractors.value.toLocaleString(
        defaultLocaleString,
        {
          maximumFractionDigits: 0,
        }
      );
  }
};

export const getElementTotalTypography = (propName: string) => {
  switch (propName) {
    case 'SumTotal':
      return 'font-weight-medium ma-1 font-italic secondary--text';
    case 'SumHours':
      return 'font-weight-medium ma-1 font-italic secondary--text';
    case 'SumTime':
      return 'font-weight-medium ma-1 font-italic secondary--text';
    case 'Factor1':
      return 'font-weight-medium ma-1 font-italic secondary--text';
    case 'Factor2':
      return 'font-weight-medium ma-1 font-italic secondary--text';
    case 'Factor3':
      return 'font-weight-medium ma-1 font-italic error--text';
    case 'Factor4':
      return 'font-weight-medium ma-1 font-italic error--text';
    case 'Factor6':
      return 'font-weight-medium ma-1 font-italic secondary--text';
    case 'Factor7':
      return 'font-weight-medium ma-1 font-italic secondary--text';
    case 'SumMaterialsUE':
      return 'font-weight-medium ma-1 font-italic secondary--text';
    case 'SumSelfCostMaterialsSubContractors':
      return 'font-weight-medium ma-1 font-italic error--text';
  }
};
